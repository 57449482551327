<template>
  <div class="content-area shuttle-plan-list">
    <hero-bar>
      {{ $t('titles.shuttleRegisterList') }}

      <b-tooltip slot="right" :label="$t('refresh')" type="is-dark" position="is-bottom">
        <b-button
          id="refresh-button"
          class="mr-10"
          size="is-circle"
          type="is-link"
          outlined
          :class="{ 'is-loading': refreshLoading }"
          icon-left="refresh"
          @click="refresh"
        />
      </b-tooltip>

      <b-button
        v-if="checkPermission('shuttle_register')"
        id="new-plan"
        slot="right"
        tag="router-link"
        :to="{ name: 'shuttle_register'}"
        icon-left="plus-circle"
        type="is-link"
      >
        {{ $t('newShuttleRegisterRequest') }}
      </b-button>
    </hero-bar>

    <section class="section is-main-section">
      <card-component class="has-table has-mobile-sort-spaced bg-none">
        <b-tabs
          v-if="checkPermission('shuttle_days_list')"
          v-model="activeTab"
          :animated="false"
          :destroy-on-hide="false"
          size="is-medium"
          type="is-boxed"
        >
          <b-tab-item label="Gün Listesi" icon="table-large">
            <smart-table
              ref="daysTable"
              data-url="shuttle-days-list"
              :sort="{
                defaultOrder: 'desc',
                field: 'register_date',
                order: 'asc',
              }"
              :filters="{ register_date: [new Date().toJSON().slice(0, 10), new Date().toJSON().slice(0, 10)], with_trashed: 1 }"
              :loading.sync="refreshLoading"
            >
              <template slot="action-column" slot-scope="{ row }">
                <b-button
                  outlined
                  size="is-small"
                  class="button-purble btn-details"
                  icon-left="arrow-right-bold"
                  @click="leadRegisterDetails(row.plan_id, row.register_date)"
                >
                  {{ $t('details') }}
                </b-button>
              </template>

              <template slot="header-right">
                <b-tooltip :label="$t('exportExcel')" type="is-dark" position="is-left">
                  <b-button
                    class="filter-button is-hidden-mobile"
                    size="is-circle"
                    icon-left="file-excel"
                    @click="activeTable.excelExport()"
                  />
                </b-tooltip>
              </template>

              <template v-slot:footer="data">
                <td colspan="6" class="has-text-weight-semibold">
                  <div class="th-wrap">Toplam:</div>
                </td>
                <td class="has-text-centered has-text-weight-semibold">
                  {{ data.data.reduce((total, item) => total + item.total - item.total_trashed, 0) }}
                </td>
                <td class="has-text-centered has-text-weight-semibold">
                  {{ data.data.reduce((total, item) => total + item.cars_count_total, 0) }}
                </td>
              </template>
            </smart-table>
          </b-tab-item>
          <b-tab-item label="Kayıtlar" icon="format-list-bulleted-square">
            <smart-table
              ref="registersTable"
              data-url="shuttle-register-list"
              :sort="{
                defaultOrder: 'desc',
                field: 'register_date',
                order: 'asc',
              }"
              :filters="{ with_trashed: 1 }"
              :loading.sync="refreshLoading"
            >
              <template slot="action-column" slot-scope="{ row }">
                <b-tooltip :label="row.trashed ? $t('canceled') : $t('cancelRequest')" type="is-dark" position="is-right">
                  <b-button
                    outlined
                    size="is-small"
                    class="button-green is-danger mr-5 only-icon"
                    icon-left="close-circle"
                    :disabled="row.trashed"
                    @click="!row.trashed && cancelRequest(row.id, false)"
                  />
                </b-tooltip>
                <b-button
                  outlined
                  size="is-small"
                  class="button-purble btn-details"
                  icon-left="arrow-right-bold"
                  @click="registerDetails(row.id)"
                >
                  {{ $t('details') }}
                </b-button>
              </template>

              <template slot="header-right">
                <b-tooltip :label="$t('exportExcel')" type="is-dark" position="is-left">
                  <b-button
                    class="filter-button is-hidden-mobile"
                    size="is-circle"
                    icon-left="file-excel"
                    @click="activeTable.excelExport()"
                  />
                </b-tooltip>
              </template>
            </smart-table>
          </b-tab-item>
        </b-tabs>

        <smart-table
          v-else
          ref="registers-table"
          data-url="shuttle-register-list"
          :sort="{
            defaultOrder: 'desc',
            field: 'register_date',
            order: 'asc',
          }"
          :filters="{ with_trashed: 1 }"
          :loading.sync="refreshLoading"
        >
          <template slot="action-column" slot-scope="{ row }">
            <b-tooltip :label="$t('cancelRequest')" type="is-dark" position="is-right">
              <b-button
                outlined
                size="is-small"
                class="button-green is-danger mr-5 only-icon"
                icon-left="close-circle"
                @click="cancelRequest(row.id, false)"
              />
            </b-tooltip>
            <b-button
              outlined
              size="is-small"
              class="button-purble btn-details"
              icon-left="arrow-right-bold"
              @click="registerDetails(row.id)"
            >
              {{ $t('details') }}
            </b-button>
          </template>
        </smart-table>
      </card-component>
    </section>

    <b-modal
      :active.sync="isDayDetailsModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <ShuttleDayDetails :shuttleId="leadShuttleId" :registerDate="leadRegisterDate" />
    </b-modal>

    <b-modal
      :active.sync="isDetailsModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <shuttle-register-details :id="registerId" />
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'ShuttleRegisterList',
  title: ({ $i18n }) => $i18n.t('titles.shuttleRegisterList'),
  components: {
    HeroBar: () => import('@/components/HeroBar'),
    CardComponent: () => import('@/components/CardComponent'),
    SmartTable: () => import('@/components/SmartTable'),
    ShuttleRegisterDetails: () => import('@/components/ShuttleRegisterDetails'),
    ShuttleDayDetails: () => import('@/components/ShuttleDayDetails'),
  },
  data () {
    return {
      refreshLoading: false,
      isDetailsModalActive: false,
      isDayDetailsModalActive: false,
      registerId: null,
      leadShuttleId: null,
      leadRegisterDate: null,
      activeTab: 0,
    }
  },
  computed: {
    activeTable () {
      if (this.$refs.daysTable) {
        return this.activeTab === 0 ? this.$refs.daysTable : this.$refs.registersTable
      }

      return this.$refs.registersTable
    },
  },
  methods: {
    refresh () {
      this.activeTable.loadData()
    },
    cancelRequest (id, all) {
      this.$buefy.dialog.confirm({
          message: 'Emin misiniz?',
          cancelText: this.$t('no'),
          confirmText: this.$t('yes'),
          type: 'is-danger',
          onConfirm: () => {
            this.$http
              .delete('shuttle-register-delete', {
                data: {
                  id: id,
                  all: all
                }
              })
              .then(() => {
                this.$buefy.toast.open({
                  message: this.$t('shuttleCancel'),
                  type: 'is-warning'
                })
                this.isDetailsModalActive = false;
                this.refresh()
              })
          }
      })
    },
    registerDetails (id) {
      this.registerId = id;
      this.isDetailsModalActive = true;
    },
    leadRegisterDetails (id, date) {
      this.leadShuttleId = id;
      this.leadRegisterDate = date;
      this.isDayDetailsModalActive = true;
    },
  },
}
</script>

<style>
  .shuttle-plan-list .table-wrapper.has-sticky-header {
    height: calc(100vh - 487px) !important;
  }
</style>
